import React from "react";
import "./testimonials.scss";

const Testimonials = () => {
  return (
    <section id="testimonial" className="testimonials">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,224L24,192C48,160,96,96,144,96C192,96,240,160,288,202.7C336,245,384,267,432,266.7C480,267,528,245,576,229.3C624,213,672,203,720,181.3C768,160,816,128,864,133.3C912,139,960,181,1008,197.3C1056,213,1104,203,1152,176C1200,149,1248,107,1296,101.3C1344,96,1392,128,1416,144L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
        ></path>
      </svg>
      <div className="container">
        <div className="row text-center text-white">
          <h1 className="display-3 fw-bold">Testimonials</h1>
          <hr className="mx-auto" />
          <p className="lead pt-1">What our purchasers are saying</p>
        </div>
        {/* START THE COROUSAL CONTENT */}
        <div className="row align-items-center">
          <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-inner">
              {/* CROUSAL ITEM 1 */}
              <div className="carousel-item active">
                {/* TESTIMONIAL CARD */}
                <div className="testimonials__card">
                  <p className="lh-lg">
                    <i className="fas fa-quote-left"></i> I recently purchased a
                    beautiful piece of Meenakari art, and I couldn't be happier
                    with my purchase. The quality of the materials used is
                    excellent, and the idol feels sturdy and well-made. The
                    colors are vibrant, and the design truly captures the
                    essence of Lord Ganesha. It has become the centerpiece of my
                    home altar, and it radiates a positive and auspicious
                    energy.
                    <i className="fas fa-quote-right"></i>
                    <div className="ratings">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </p>
                </div>
                {/* PRODUCT PICTURE */}
                {/* <div className="testimonials__picture">
                  <img src={ganeshji} alt="" className="img-fluid" />
                </div> */}
                {/* PURCHASER NAME AND ADDRESS */}
                <div className="testimonials__name">
                  <h3>Kritika Narang</h3>
                  <p className="fw-light">
                    <i className="fas fa-location-dot"></i> Varanasi
                  </p>
                </div>
              </div>
              {/* CROUSAL ITEM 2 */}
              <div className="carousel-item">
                {/* TESTIMONIAL CARD */}
                <div className="testimonials__card">
                  <p className="lh-lg">
                    <i className="fas fa-quote-left"></i>
                    The craftsmanship and attention to detail on the peacock
                    idol are truly remarkable. The delicate meenakari work in
                    vibrant shades of pink and other stunning colors brings the
                    peacock to life. Every intricate design and pattern is
                    meticulously handcrafted, showcasing the skill and expertise
                    of the artisans.
                    <i className="fas fa-quote-right"></i>
                    <div className="ratings">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </p>
                </div>
                {/* PURCHASER NAME AND ADDRESS */}
                <div className="testimonials__name">
                  <h3>Vaibhav Vishwakarma</h3>
                  <p className="fw-light">
                    <i className="fas fa-location-dot"></i> Varanasi
                  </p>
                </div>
              </div>
              {/* CROUSAL ITEM 3 */}
              <div className="carousel-item">
                {/* TESTIMONIAL CARD */}
                <div className="testimonials__card">
                  <p className="lh-lg">
                    <i className="fas fa-quote-left"></i>
                    Waiting for more purchaser responses
                    <i className="fas fa-quote-right"></i>
                    <div className="ratings">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center pt-2">
              <button
                className="btn btn-outline-light fas fa-long-arrow-alt-left"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              ></button>
              <button
                className="btn btn-outline-light fas fa-long-arrow-alt-right"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,224L24,192C48,160,96,96,144,96C192,96,240,160,288,202.7C336,245,384,267,432,266.7C480,267,528,245,576,229.3C624,213,672,203,720,181.3C768,160,816,128,864,133.3C912,139,960,181,1008,197.3C1056,213,1104,203,1152,176C1200,149,1248,107,1296,101.3C1344,96,1392,128,1416,144L1440,160L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Testimonials;
