import "./App.scss";
import Header from "./sections/Header/header";
import Intro from "./sections/Intro/intro";
import About from "./sections/About/about";
import Gallery from "./sections/Gallery/gallery";
import Testimonials from "./sections/Testimonials/testimonials";
import Faq from "./sections/FrequentlyAQ/faq";
import Portfolio from "./sections/Portfolio/portfolio";
import Footer from "./sections/Footer/footer";
import Buynow from "./sections/BuyNow/buynow";
function App() {
  return (
    <div className="App">
      <Header />
      <Intro />
      <About />
      <Gallery />
      <Testimonials />
      <Faq />
      <Portfolio />
      <Buynow />
      <Footer />
    </div>
  );
}

export default App;
