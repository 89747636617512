import React from "react";
import "./gallery.scss";
import elephant from "../../assets/images/elephant_no_bg.png";
import jwelleryShop from "../../assets/images/Jewelry-Shop.png";

const Gallery = () => {
  return (
    <section>
      <div className="container mt-2">
        <div className="row pt-4 text-center">
          <h1 className="display-3 fw-bold">
            Collections: Classics & Contemporary
          </h1>
          <div className="heading-line mb-1"></div>
        </div>
      </div>
      <div className="container">
        {/* Meenakari */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 collections mt-1 ">
            <div className="collections__content text-center">
              <i className="fa-solid fa-paintbrush d-block"></i>
              <h3 className="display-3--title mt-2">Meenakari Art</h3>
              <p className="lh-lg">
                We are passionate about preserving and showcasing the rich
                heritage of Meenakari art, a centuries-old Indian craft renowned
                for its vibrant colors and intricate detailing. We are proud to
                carry forward this legacy, showcasing the artistry and
                craftsmanship that flourished in this city until a century ago.
              </p>
              <button
                type="button"
                className="rounded-pill btn-rounded border-primary"
              >
                See More
                <span>
                  <i className="fas fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 collections mt-1">
            <div className="collections__pic">
              <img
                src={elephant}
                alt="Meenakari Elephant"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        {/* Other Silver and Gold Jewellery */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 types mt-1">
            <div className="collections__content text-center">
              <i className="fa-solid fa-ring d-block"></i>
              <h3 className="display-3--title mt-2">
                Silver and Gold Jewellery
              </h3>
              <p className="lh-lg">
                Embracing the allure of gold and silver, we also present an
                exquisite range of jewelry that captivates the senses and
                indulges the discerning connoisseur. Crafted with utmost
                precision and attention to detail, our collection boasts an
                array of timeless classics and contemporary marvels, designed to
                elevate any occasion and adorn every style.{" "}
              </p>
              <button
                type="button"
                className="rounded-pill btn-rounded border-primary"
              >
                See More
                <span>
                  <i className="fas fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 types mt-0">
            <div className="collections__pic">
              <img src={jwelleryShop} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
