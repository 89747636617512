import React from "react";
import "./buynow.scss";
import whatsapp from "../../assets/images/whatsapp.jpeg";
const Buynow = () => {
  return (
    <section id="contact" className="get-started">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold text-capitalize">Buy Now</h1>
          <div className="heading-line"></div>
          <p className="lh-lg">
            Explore our exquisite collection of Banaras Gulabi Meenakari
            products. Each piece is meticulously handcrafted with intricate
            designs and vibrant colors, showcasing the rich heritage of Banaras
            craftsmanship.
          </p>
        </div>
        <div className="row text-white">
          <div className="col-12 col-lg-6 gradient shadow p-3">
            <div className="cta-info w-100">
              <h4 className="display-4 fw-bold">
                100% Satisfaction Guaranteed
              </h4>
              <h3 className="display-3--brief mt-3">What will be next step?</h3>
              <ul className="cta-info__list">
                <li>
                  <i class="fa-solid fa-check mt-3"></i>Reach out to us through
                  WhatsApp or visit our shop for personalized assistance.
                </li>
                <li>
                  <i class="fa-solid fa-check mt-3"></i>Select the desired item
                  from our captivating collection, tailored to your unique
                  preferences.
                </li>
                <li>
                  <i class="fa-solid fa-check mt-3"></i>Place your order
                  securely and experience the convenience of doorstep delivery.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 bg-white shadow p-3">
            <div className="row text-center text-dark">
              <div className="whatsapp mt-5">
                <h1 className="display-3">Whatsapp</h1>
                <div className="mt-5">
                  <img src={whatsapp} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="shop"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Buynow;
