import React from "react";
import "./header.scss";
import logo from "../../assets/images/logo-round.jpeg";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark shadow fixed-top menu">
      <div className="container">
        <a className="navbar-brand" href="#home">
          <img
            src={logo}
            className="rounded-pill"
            alt="Logo Img"
            style={{ height: "3.5rem" }}
          />{" "}
          <span className="navbar-title-text">
            Banaras Gulabi Meenakari Artist
          </span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#portfolio">
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
            <a href="tel:+919415984124">
              <button type="button" className="rounded-pill btn-rounded">
                +91 9415984124
                <span>
                  <i className="fas fa-phone-alt"></i>
                </span>
              </button>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
