import React, { useEffect, useRef } from "react";
import "./intro.scss";
import videoImage from "../../assets/images/intro-image.png";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

const Intro = () => {
  const lightboxRef = useRef(null);

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox", // Use a class selector for the elements that trigger the lightbox
    });

    return () => {
      lightbox.destroy(); // Clean up the lightbox when the component unmounts
    };
  }, []);
  return (
    <section id="home" className="intro-section">
      <div className="container">
        <div className="row align-items-center text-white">
          {/* CONTENTS FOR VIDEO */}

          <div className="col-md-6 intros text-start">
            <div className="video-box">
              <img src={videoImage} alt="Meenakari Art" className="img-fluid" />
              <a
                href="https://www.youtube.com/watch?v=EGUvryu-Msw&feature=youtu.be"
                className="position-absolute top-50 start-50 translate-middle glightbox"
                ref={lightboxRef}
              >
                <span>
                  <i className="fas fa-play-circle"></i>
                </span>
                <span className="border-animation border-animation--border-1"></span>
                <span className="border-animation border-animation--border-2"></span>
              </a>
            </div>
          </div>
          {/* CONTENT FOR INTRO */}
          <div className="col-md-6 intros text-end">
            <h1 className="display-2">
              <span className="display-2--intro">
                Banaras Gulabi Meenakari Artist
              </span>
              <span className="display-2--description lh-base">
                Banaras Gulabi Meenakari, a unique art also known as enameling,
                the art of ornamenting metal surface enamel. In olden days the
                meenakari art was done on gold but later on it is done on silver
                also.. Meenakari is not just limited to traditional jewellery
                but expands into more modern products including exquisite
                ornaments, earrings, Traditional boxes, figures of deities and
                decorative showpieces etc. The colours used in the gulabi
                meenakari are natural and leadless dyes.
              </span>
            </h1>
            <button type="button" className="rounded-pill btn-rounded">
              Get in touch
              <span>
                <i className="fas fa-arrow-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,64L48,64C96,64,192,64,288,80C384,96,480,128,576,122.7C672,117,768,75,864,90.7C960,107,1056,181,1152,197.3C1248,213,1344,171,1392,149.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Intro;
