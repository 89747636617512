import React, { useState } from "react";

const Faq = () => {
  const [activeAccordion, setActiveAccordion] = useState("");

  const handleAccordionClick = (accordionId) => {
    setActiveAccordion(activeAccordion === accordionId ? "" : accordionId);
  };
  return (
    <section id="faq" className="faq">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold">FAQ</h1>
          <div className="heading-line"></div>
          <p className="lead mt-3">
            Frequently Asked Questions: Get information beforehand.
          </p>
        </div>
        {/* ACCORDIAN CONTENT */}
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="accordion" id="accordionExample">
              {/* ACCORDIAN ONE */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      activeAccordion === "collapseOne" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseOne")}
                    aria-expanded={
                      activeAccordion === "collapseOne" ? "true" : "false"
                    }
                    aria-controls="collapseOne"
                  >
                    Do you have an online store or do you only operate offline?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    activeAccordion === "collapseOne" ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    At the moment, we do not have our own dedicated online
                    store. However, we have made some of our exquisite products
                    available for purchase on popular e-commerce platforms like
                    Amazon and Flipkart. We understand the convenience and
                    accessibility that online shopping offers, and we are
                    actively working towards establishing our own online
                    presence in the near future. In the meantime,{" "}
                    <strong>
                      we encourage interested buyers to reach out to us directly
                      through WhatsApp or visit our physical store.
                    </strong>
                    By doing so, you can obtain detailed information about our
                    latest art pieces, discuss specific requirements, and
                    experience the beauty of our craftsmanship firsthand. We are
                    committed to providing personalized assistance and ensuring
                    a delightful shopping experience for every customer.
                  </div>
                </div>
              </div>
              {/* ACCORDIAN TWO */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      activeAccordion === "collapseTwo" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseTwo")}
                    aria-expanded={
                      activeAccordion === "collapseTwo" ? "true" : "false"
                    }
                    aria-controls="collapseTwo"
                  >
                    Can I return or exchange a product if I'm not satisfied with
                    my purchase?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${
                    activeAccordion === "collapseTwo" ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Absolutely! We value customer satisfaction and want you to
                    be delighted with your purchase. If you are not completely
                    satisfied with a product you have bought from us,{" "}
                    <strong>
                      you may be eligible for a return or exchange
                    </strong>
                    , provided that the product is undamaged and falls within
                    our return policy guidelines. We have a clear and
                    transparent return policy in place to ensure a hassle-free
                    process for our customers. Please refer to our return
                    policy, which outlines the conditions and timeframes for
                    returns and exchanges. Our goal is to address any concerns
                    or issues promptly and find a satisfactory resolution for
                    you. If you have any questions or need further assistance
                    regarding returns or exchanges, our dedicated customer
                    service team is here to help. Please don't hesitate to reach
                    out to us on WhatsApp, and we'll be more than happy to
                    assist you.
                  </div>
                </div>
              </div>
              {/* ACCORDIAN THREE */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      activeAccordion === "collapseThree" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseThree")}
                    aria-expanded={
                      activeAccordion === "collapseThree" ? "true" : "false"
                    }
                    aria-controls="collapseThree"
                  >
                    Do you offer any discounts or promotions?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${
                    activeAccordion === "collapseThree" ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Banaras Gulabi Meenakari is a true form of art that reflects
                    the rich cultural heritage of our region. We take immense
                    pride in the craftsmanship and intricacy of our creations.
                    As a result, our prices are set to reflect the value of the
                    artistry and the effort put into each piece. We believe that
                    our art should be appreciated and valued for its quality and
                    beauty. While{" "}
                    <strong>
                      we do not typically offer regular discounts or promotions,
                      we do occasionally provide special offers during festive
                      occasions
                    </strong>{" "}
                    in India. These promotions are our way of celebrating the
                    spirit of the festival and sharing the joy with our
                    customers. We invite you to stay connected with us through
                    our website, social media channels, or by subscribing to our
                    newsletter to be notified about any upcoming discounts or
                    promotions. Rest assured, our commitment to delivering
                    exceptional quality and craftsmanship remains unwavering,
                    and we believe that every piece of Banaras Gulabi Meenakari
                    art is worth its value.
                  </div>
                </div>
              </div>
              {/* ACCORDIAN FOUR */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      activeAccordion === "collapseFour" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseFour")}
                    aria-expanded={
                      activeAccordion === "collapseFour" ? "true" : "false"
                    }
                    aria-controls="collapseFour"
                  >
                    What are your store hours and location?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className={`accordion-collapse collapse ${
                    activeAccordion === "collapseFour" ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Thank you for your inquiry!{" "}
                    <strong>
                      Our store hours are from 10am to 10pm, allowing you ample
                      time to explore our exquisite collection. We are
                      conveniently located at A1/22-A, Gayghat (Near Balai Beer
                      Gali), Varanasi 221001.
                    </strong>{" "}
                    For precise directions to our store, you can visit our
                    website and navigate to the "Contact Us" section located at
                    the bottom of the page. There, you will find a Google Maps
                    integration that will assist you in obtaining accurate
                    directions from your current location. Should you have any
                    additional questions or require further assistance, please
                    feel free to reach out to us. We look forward to your visit
                    and the opportunity to share the beauty of our Gulabi
                    Meenakari art with you.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
