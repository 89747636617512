import React from "react";
import "./about.scss";
import aboutImage from "../../assets/images/about -section.jpeg";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold">Our Story</h1>
          <div className="heading-line mb-5"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 about text-center">
            <div className="about__content about__content-text">
              <h3 className="display-3 display-2--title mt-1">What we do?</h3>
              <p className="lh-lg  display-2--description">
                Welcome to our shop, where we carry on the tradition of
                Meenakari, the exquisite art of enamelling. Passed down through
                six generations.With each piece we create, we strive to capture
                the essence of the bygone era, infusing it with a contemporary
                touch. Situated in Varanasi, one of the oldest cities in India,
                our shop is deeply rooted in a rich cultural and historical
                heritage. Varanasi, the capital of the ancient Kashi region, has
                long been associated with the art of meenakari. We are proud to
                carry forward this legacy, showcasing the artistry and
                craftsmanship that flourished in this city until a century ago.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 about mt-4 text-center bg-dark text-white">
            <div className="about__content">
              <img
                src={aboutImage}
                alt="MEENAKARI"
                className="img-fluid about-image shadow p-3 bg-white rounded"
              />
              <h3 className="mt-5 fw-bold"> National Awardee</h3>
              <h4 className="fw-bold">Mr. Ramesh Kumar Vishwakarma</h4>
              <p className="display-2--description">
                In this art he is the fifth generation since the start of this
                art form in his family. He learnt this from his father Late. Mr
                Ramadhar Vishwakarma and he trained his family members in the
                same profession
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
